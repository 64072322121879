































































































import { Component, Vue, Ref } from "vue-property-decorator";
import { passwordreset } from "@/api/patients";
import { Message } from "element-ui";

@Component({
  name: "Register",
})
export default class extends Vue {
  @Ref("password") readonly elPassword!: HTMLInputElement;
  @Ref("confirmPassword") readonly elConfirmPassword!: HTMLInputElement;
  @Ref("form") readonly elForm!: HTMLFormElement;

  private registerForm = {
    password: "",
    token: this.$route.query.token,
  };
  private patientId = this.$route.query.patient == null ? "0" : this.$route.query.patient.toString();
  private confirmPassword = "";
  private passwordType = "password";
  private passwordType2 = "password";
  private loading = false;
  private formValidated = false;

  get passwordConfirmRegEx() {
    return "^" + this.registerForm.password + "$";
  }

  private showPwd() {
    this.passwordType = this.passwordType === "password" ? "" : "password";
    this.$nextTick(() => {
      this.elPassword.focus();
    });
  }
  private showPwd2() {
    this.passwordType2 = this.passwordType2 === "password" ? "" : "password";
    this.$nextTick(() => {
      this.elConfirmPassword.focus();
    });
  }

  private async onChangePassword() {
    // Vérifier la validité des champs
    if (
      this.elForm.checkValidity() === false ||
      this.registerForm.password !== this.confirmPassword
    ) {
      this.formValidated = true;
    } else {
      // Inscription
      this.loading = true;
      try {
        const { data } = await passwordreset(this.patientId, this.registerForm);
        Message({
          message: "Votre mot de passe a été mis à jour. Vous allez être redirigé à l'accueil.",
          type: "success",
          duration: 5 * 1000,
          onClose: this.onRedirectLogin,
        });
      } finally {
        this.loading = false;
      }
    }
  }

  private onRedirectLogin() {
    this.$router.push(`/login`);
  }
}
